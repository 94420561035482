import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'antd';
import { Badge } from 'reactstrap'

const RemoveButtonGroup = ({ onRemoveClick, children, ...props }) => (
  <Button.Group
    style={{
      marginLeft: 8,
      display: 'flex',
      alignItems: 'center'
    }}
    {...props}
  >
    {children}
    <Badge
      color="danger"
      className='badge-md badge-circle badge-floating border-white'
      onClick={onRemoveClick}
    >
       <i className='fas fa-times'></i>
    </Badge>
  </Button.Group>
);

RemoveButtonGroup.propTypes = {
  onRemoveClick: PropTypes.func.isRequired
};
export default RemoveButtonGroup;

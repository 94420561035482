import React from 'react';
import * as PropTypes from 'prop-types';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  UncontrolledTooltip,
} from 'reactstrap';
import { shouldLimitData } from '../../helpers';
import { dataSizeLimitMessages } from '../../messages';

const ChartTypes = [
  // Disabling area and line charts for now
  // {
  //   name: 'line',
  //   title: 'Linha',
  //   icon: 'fas fa-chart-line',
  // },
  // {
  //   name: 'area',
  //   title: 'Área',
  //   icon: 'fas fa-chart-area',
  // },
  {
    name: 'bar',
    title: 'Barra',
    icon: 'fas fa-chart-bar',
  },
  {
    name: 'pie',
    title: 'Pizza',
    icon: 'fas fa-chart-pie',
  },
  {
    name: 'table',
    title: 'Tabela',
    icon: 'fas fa-table',
  },
  {
    name: 'number',
    title: 'Número',
    icon: 'fas fa-calculator',
  },
];

const ToolTip = ({ chartType }) => {
  return (
    <UncontrolledTooltip
    placement="right"
    target={chartType}
    >
      {dataSizeLimitMessages[chartType]}
    </UncontrolledTooltip>
  );
};

const SelectChartType = ({ chartType, updateChartType, dataSize }) => {
  const foundChartType = ChartTypes.find((t) => t.name === chartType);

  return (
    <UncontrolledDropdown>
      <DropdownToggle color='success'>
        <i className={`${foundChartType.icon} mr-2`}></i> {foundChartType.title}
      </DropdownToggle>
      <DropdownMenu>
        {ChartTypes
          .map((m) => {
            const showToolTip = shouldLimitData(dataSize, m.name);
            return (
              <span id={m.name} key={m.title}>
                <DropdownItem
                  onClick={() => updateChartType(m.name)}
                >
                  <i className={m.icon}></i> {m.title}
                </DropdownItem>
                { showToolTip && <ToolTip chartType={m.name} /> } 
              </span>
            )
          })
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

SelectChartType.propTypes = {
  chartType: PropTypes.string.isRequired,
  updateChartType: PropTypes.func.isRequired,
};
export default SelectChartType;

import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5exporting from '@amcharts/amcharts5/plugins/exporting';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { v4 as uuid } from 'uuid';

function LineChartAM({ resultSet, chartSize }) {
  const id = uuid();
  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new(id);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX:true,
      layout: root.verticalLayout
    }));

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let timeUnit;
    if (typeof resultSet.query().timeDimensions[0] === "undefined") {
      timeUnit = null;
    } else {
      timeUnit = resultSet.query().timeDimensions[0].granularity;
    }
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.2,
      baseInterval: {
        timeUnit,
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }));

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    function dataParser(data) {
      return data.map((entry) => {
        const { x, value } = entry;
        const epochDate = Date.parse(x); 
        return {
          date: epochDate,
          value
        }
      })
    }

    // Creating series dinamically
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createLineChartSeries(name, data) {
      let series = chart.series.push(am5xy.LineSeries.new(root, {
        name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{name}[/]: {valueY}"
        })
      }));
      series.data.setAll(data);
      series.appear(1000);
    }

    const dataToDisplay = resultSet.series();
    const dataNames = resultSet.seriesNames();
    dataToDisplay.forEach(({series}, index) => {
      const parsedData = dataParser(series);
      const name = dataNames[index].title;
      createLineChartSeries(name, parsedData)
    })

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal"
    }));

    // Adds option to export data as an image or data
    // https://www.amcharts.com/docs/v5/concepts/exporting/
    am5exporting.Exporting.new(root, {
      menu: am5exporting.ExportingMenu.new(root, {
        align: "left",
      }),
      dataSource: resultSet.chartPivot(),
      numericFields: resultSet.seriesNames(),
      XLSXOptions: {
        disabled: false,
      },
    });

    // Add legend
    // https://www.amcharts.com/docs/v5/concepts/legend/
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50)
    }));
    legend.labels.template.setAll({
      maxWidth: 150,
      oversizedBehavior: "truncate"
    })
    legend.data.setAll(chart.series.values);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
    
    // Auto-adjusting chart height
    root.events.once("frameended", () => {
      const chartHeight = 40 * chartSize
      chart.root.dom.style.height = chartHeight + "px";
    });

    return () => {
      root.dispose();
    };

  }, [resultSet, chartSize, id]);

  return (
    <div id={id} style={{ width: "100%" }}></div>
  );
}

export default LineChartAM;

import parseJwt from "./parseJwt";

const setUserInfoLocalStorage = (response) => {
  const jwtDecoded = parseJwt(window.localStorage.getItem("jwt") || process.env.REACT_APP_TOKEN);
  window.localStorage.setItem("email", response.user.email);
  window.localStorage.setItem("account_id", jwtDecoded.account_id);
  window.localStorage.setItem("account_name", jwtDecoded.account_name)
  window.localStorage.setItem("user_id", jwtDecoded.user_id);
}

export default setUserInfoLocalStorage;

import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import ChartRenderer from '../components/ChartRenderer';
import Dashboard from '../components/Dashboard';
import DashboardItem from '../components/DashboardItem';
import { MixPanel } from '../helpers/MixPanel';
import { getDashboards } from '../helpers/api';
import ModalWelcomeVideo from '../components/ModalWelcomeVideo';
import LoadingPage from '../components/LoadingPage';

const Empty = () => (
  <div className='p-3 text-center container-sm'>
    <Alert className='alert-default' style={{ margin: '0 auto 20px' }}>
      <h2 className='text-white mb-4'>Não há nada por aqui ainda...</h2>
      <p className='text-white'>Vamos criar seu primeiro painel?</p>
    </Alert>
    <Link to='/explore'>
      <Button className='btn-icon btn-3' color='primary' type='button'>
        <span className='btn-inner--icon'>
          <i className='fas fa-plus-circle'></i>
        </span>
        <span className='btn-inner--text'>Criar painel</span>
      </Button>
    </Link>
  </div>
);

const defaultLayout = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 4,
  minH: 8,
});

const DashboardPage = () => {
  const [modalOpen, setModalOpen] = useState();
  const [itemToDestroy, setItemToDestroy] = useState();
  const [loading, setLoading] = useState(true);
  const [dashboards, setDashboards] = useState([]);
  const [error, setError] = useState('');
  const [render, setRender] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      getDashboards()
        .then((data) => {
          setDashboards(data)
        })
        .catch((error) => setError(error.message))
        .finally(() => setLoading(false))
    };
    setLoading(true);
    fetchData();
    MixPanel.dashboardPageHit();
  }, [render]);

  if (loading) {
    return <LoadingPage />
  }

  if (error) {
    return (
      <Alert
        message={error.toString()}
        description={error.toString()}
        type='error'
      />
    );
  }

  const dashboardItem = (item) => {
    return (
    <div id={item.id} key={item.id} data-grid={defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        itemId={item.id}
        title={item.dashboard_config.name}
        isModalOpen={modalOpen}
        onModalOpenChange={setModalOpen}
        itemToDestroy={itemToDestroy}
        setItemToDestroy={setItemToDestroy}
        setRender={setRender}
      >
        <ChartRenderer vizState={item.dashboard_config.vizState} />
      </DashboardItem>
    </div>
  )};

  return dashboards.length ? (
    <Dashboard dashboardItems={dashboards}>
      {dashboards.map(dashboardItem)}
    </Dashboard>
  ) : (
    <>
      <ModalWelcomeVideo />
      <Empty />
    </>
  );
};

export default DashboardPage;

import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from 'reactstrap';
import MemberDropdown from './MemberDropdown';
import { useTranslation } from 'react-i18next';

const DateRanges = [
  {
    title: 'All time',
    value: undefined,
  },
  {
    value: 'Today',
  },
  {
    value: 'Yesterday',
  },
  {
    value: 'This week',
  },
  {
    value: 'This month',
  },
  {
    value: 'This quarter',
  },
  {
    value: 'This year',
  },
  {
    value: 'Last 7 days',
  },
  {
    value: 'Last 30 days',
  },
  {
    value: 'Last week',
  },
  {
    value: 'Last month',
  },
  {
    value: 'Last quarter',
  },
  {
    value: 'Last year',
  },
];

const DateRangeMenu = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <DropdownMenu>
      {DateRanges.map((m) => (
        <DropdownItem key={m.title || m.value} onClick={() => onClick(m)}>
          {m.title ? t(`${m.title}`) : t(`${m.value}`) }
        </DropdownItem>
      ))}
    </DropdownMenu>
  );
};

const timePeriodsToSelect = (availableMembers, selectedMeasures) => {
  const selectedNames = selectedMeasures.map((measure) => measure.name.split('.')[0])
  const membersToRender = availableMembers
    .filter((member) => {
      return selectedNames.includes(member.name.split('.')[0]);
    });
  return membersToRender;
};

const GranularityMenu = ({ member, onClick }) => {
  const { t } = useTranslation();
  return (
  <DropdownMenu>
    {member.granularities.length ? (
      member.granularities
        .filter((m) => m.name !== 'second' && m.name !== 'hour' && m.name !== 'minute')
        .map((m) => (
          <DropdownItem key={m.title} onClick={() => onClick(m)}>
            {t(`${m.title}`)}
          </DropdownItem>
        ))
    ) : (
      <DropdownItem disabled>No members found</DropdownItem>
    )}
  </DropdownMenu>
  );
};

const TimeGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  updateOrder,
  selectedMeasures,
}) => {
  const { t } = useTranslation();
  const membersToRender = timePeriodsToSelect(availableMembers, selectedMeasures);

  return (
    <>
      {members.map((m) => [
        <>
        <MemberDropdown
          key={m.dimension.title}
          onClick={(newDimension) =>
            updateMethods.update(m, { ...m, dimension: newDimension })
          }
          availableMembers={membersToRender}
          color='success'
          className='mr-3'
        >
          <span>{m.dimension.title}</span>
        </MemberDropdown>
        <div style={{'marginTop': '10px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
        <UncontrolledDropdown
          direction='right'
          key={`${m.dimension.name}-date-range`}
        >
          <DropdownToggle color='default' outline>
            {t(`${m.dateRange}`, 'Sempre')}
          </DropdownToggle>
          <DateRangeMenu onClick={(dateRange) =>
            updateMethods.update(m, { ...m, dateRange: dateRange.value })
          }/>
        </UncontrolledDropdown>
        <b key={`${m.dimension.name}-by`}>POR</b>
        <UncontrolledDropdown
          className='ml-2'
          direction='right'
          key={`${m.dimension.name}-granularity`}
        >
          <DropdownToggle color='default' outline>
            {t(`${m.dimension.granularities.find((g) => g.name === m.granularity) &&
              m.dimension.granularities.find((g) => g.name === m.granularity)
                .title}`)}
          </DropdownToggle>
          <GranularityMenu
            member={m.dimension}
            onClick={
              (granularity) =>
              updateMethods.update(m, { ...m, granularity: granularity.name })
            }
          />
        </UncontrolledDropdown>
          <Badge
            color='danger'
            className='badge-md badge-circle badge-floating border-white'
            onClick={() => updateMethods.remove(m)}
          >
            <i className='fas fa-times'></i>
          </Badge>
        </div>
        </>
      ])}
      {!members.length && (
        <MemberDropdown
          onClick={(member) =>
            updateMethods.add({
              dimension: member,
              granularity: 'month',
            })
          }
          availableMembers={membersToRender}
          color='success'
        >
          <i className='fas fa-history mr-2'></i>
          {addMemberName}
        </MemberDropdown>
      )}
    </>
  );
};

TimeGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired,
};
export default TimeGroup;

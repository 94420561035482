import React from "react";

const IntroVideo = () => {
  return (
    <iframe
    width="640"
    height="360"
    src="https://www.youtube.com/embed/pRtxPwy5ac4"
    title="Facilite sua rotina a com Inteligov"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    >
  </iframe>
  )
}

export default IntroVideo;

import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Select } from 'antd';
import MemberDropdown from './MemberDropdown';
import FilterInput from './FilterInput';
import RemoveButtonGroup from './RemoveButtonGroup';
import { Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import filterDashboardFilters from '../../helpers/filterDashboardFilters';
import { fullHDscreenWidth } from '../../constants';
import { getWindowSize } from '../../helpers';

const AvailableFilters = ({member, updateMethods, availableMembers}) => {
  return (
    <Col className="col-md-auto">
    <MemberDropdown
      type="selected-filter"
      onClick={updateWith => updateMethods.update(member, {...member, dimension: updateWith })}
      availableMembers={availableMembers}
      color='success'
      className='mr-3'
    >
      {member.dimension.title}
    </MemberDropdown>
  </Col>
  )
}

const SingleFilter = ({ member, updateMethods }) => {
  const { t } = useTranslation();
  return (
    <>
    <Col className="col-md-auto">
      <Select
        value={member.operator}
        onChange={(operator) => updateMethods.update(member, {...member, operator})}
        style={{
          width: 160,
          marginRight: 8,
          display: 'flex',
          alignItems: 'center'
        }}
        placeholder='Selecione o critério'
      >
        {member.operators
          .filter(operator => operator.name !== 'notSet')
          .map(operator => (
          <Select.Option
            key={operator.name}
            value={operator.name}
          >
            {t(`${operator.title}`)}
          </Select.Option>
        ))}
      </Select>
    </Col>
    <Col className="col-md-auto">
      <FilterInput member={member} key="filterInput" updateMethods={updateMethods} />
    </Col>
    <Col className="col-md-auto">
      <RemoveButtonGroup onRemoveClick={() => updateMethods.remove(member)} />
    </Col>
  </>
  )
}

const SmallFilter = ({ member, updateMethods, availableMembers }) => {
  return (
  <Col>
    <Row style={{"marginBottom": "10px"}}>
      <AvailableFilters member={member} updateMethods={updateMethods} availableMembers={availableMembers} />
    </Row>
    {/* Filter operators (contain, does not contain etc) */}
    <Row>
      <SingleFilter member={member} updateMethods={updateMethods} />
    </Row>
  </Col>
)};

const BigFilter = ({ member, updateMethods, availableMembers }) => {
  return (
    <>
      <AvailableFilters member={member} updateMethods={updateMethods} availableMembers={availableMembers} />
      {/* Filter operators (contain, does not contain etc) */}
      <SingleFilter member={member} updateMethods={updateMethods} />
    </>
  )
}

const FilterGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  selectedDimensions,
  selectedMeasures,
}) => {
  const [windowWidth, setWindowWidth] = useState(getWindowSize());
  const selectedMembers = [...selectedDimensions, ...selectedMeasures];
  const filtersToRender = filterDashboardFilters(selectedMembers, availableMembers);

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      <Col className="col-md-auto">
        <MemberDropdown
          onClick={(m) => updateMethods.add({ dimension: m})}
          availableMembers={filtersToRender}
          type="new"
          color="success"
        >
          <i className='fas fa-filter mr-2'></i>
          {addMemberName}
        </MemberDropdown>
      </Col>
      <Col>
      {members.map((member) => (
        <Row key={member.index} style={{ marginBottom: 10 }}>
          {
            windowWidth >= fullHDscreenWidth ?
              <BigFilter member={member} updateMethods={updateMethods} availableMembers={availableMembers} /> :
              <SmallFilter member={member} updateMethods={updateMethods} availableMembers={availableMembers} />
          }
        </Row>
        ))}
      </Col>
    </>
  )
};

FilterGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired,
};
export default FilterGroup;

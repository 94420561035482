import mixpanel from "mixpanel-browser";
import getUserInfoLocalStorage from "./getUserInfoLocalStorage";

const mixPanelToken = process.env.REACT_APP_MIXPANEL_API_TOKEN;
const env_check = process.env.NODE_ENV === 'production';

mixpanel.init(mixPanelToken, {});

const actions = {
  explorePageHit: () => {
    if (env_check) {
      mixpanel.track('Hit explore page', {
      source: 'Explore Page',
      user: getUserInfoLocalStorage(),
      });
    };
  },
  dashboardPageHit: () => {
    if (env_check) {
      mixpanel.track('Hit dashboard page', {
        source: 'Dashboard Page',
        user: getUserInfoLocalStorage(),
      });
    }
  },
  saveDashboard: (query) => {
    if (env_check) {
      mixpanel.track('Save dashboard', {
        source: 'Dashboard Page',
        user: getUserInfoLocalStorage(),
        query,
      });
    }
  },
};

export const MixPanel = actions;

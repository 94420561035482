import _ from 'lodash';
import React from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { updateLayout } from '../helpers/api';
const ReactGridLayout = WidthProvider(RGL);

const Dashboard = ({ children, dashboardItems }) => {

  const onLayoutChange = (newLayout) => {
    newLayout.forEach(async (l) => {
      const item = dashboardItems.find((i) => i.id.toString() === l.i);
      const toUpdate = {
        x: l.x,
        y: l.y,
        w: l.w,
        h: l.h,
      };

      if (item && !_.isEqual(toUpdate, item.layout)) {
        await updateLayout(item.id, toUpdate);
      }
    });
  };

  return (
    <ReactGridLayout cols={12} rowHeight={50} onLayoutChange={onLayoutChange}>
      {children}
    </ReactGridLayout>
  );
};

export default Dashboard;

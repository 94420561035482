import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Input,
} from 'reactstrap';
import { MixPanel } from '../helpers/MixPanel';
import { saveDashboard, updateDashboard } from '../helpers/api';

const TitleModal = ({
  history,
  itemId,
  titleModalVisible,
  setTitleModalVisible,
  setAddingToDashboard,
  finalVizState,
  setTitle,
  finalTitle,
}) => {
  const handleFormSubmit = async () => {
    setTitleModalVisible(false);
    setAddingToDashboard(true);
    (itemId ? updateDashboard(itemId, finalTitle, finalVizState) :
    saveDashboard(finalTitle, finalVizState))
      .then(() => MixPanel.saveDashboard(finalVizState.query))
      .then(() => setAddingToDashboard(false))
      .then(() => history.push('/'));
  };

  return (
    <Modal
      fade={false}
      key='modal'
      autoFocus={false}
      isOpen={titleModalVisible}
      onCancel={() => setTitleModalVisible(false)}
    >
      <ModalHeader className='border-bottom bg-secondary'>
        Salvar painel
      </ModalHeader>
      <ModalBody>
        <Input
          type='text'
          placeholder='Nome do painel'
          autoFocus
          value={finalTitle}
          onChange={(e) => setTitle(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleFormSubmit()}
        />
      </ModalBody>
      <ModalFooter className='border-top bg-secondary'>
        <Button
          color='secondary'
          onClick={() => {
            setTitleModalVisible(false);
            setAddingToDashboard(false);
          }}
        >
          Cancelar
        </Button>
        <Button color='success' onClick={handleFormSubmit}>
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TitleModal;

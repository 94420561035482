import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'br',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      br: {
        translation:{
          ">": "Maior que",
          "<": "Menor que",
          ">=": "Maior ou igual à",
          "<=": "Menor ou igual à",
          "after date": "Depois de",
          "All time": "Sempre",
          "before date": "Antes de",
          "contains": "Contém",
          "Day": "Dia",
          "does not contain": "Não contém",
          "does not equal": "Não é igual à",
          "equals": "Exatamente igual à",
          "Hour": "Hora",
          "in date range": "No intervalo de",
          "is set": "Não é nulo",
          "Last 7 days": "Últimos 7 dias",
          "Last 30 days": "Últimos 30 dias",
          "Last month": "Último mês",
          "Last quarter": "Último trimestre",
          "Last week": "Última semana",
          "Last year": "Último ano",
          "Minute": "Minuto",
          "Month": "Mês",
          "not in date range": "Não está no intervalo de",
          "Quarter": "Trimestre",
          "Second": "Segundo",
          "This month": "Nesse mês",
          "This quarter": "Nesse trimestre",
          "This week": "Nessa semana",
          "This year": "Nesse ano",
          "Today": "Hoje",
          "w/o grouping": "Sem agrupamento",
          "Week": "Semana",
          "Year": "Ano",
          "Yesterday": "Ontem",
        }
      }
    }
  });

export default i18n;

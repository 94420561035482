import React from 'react';
import * as PropTypes from 'prop-types';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Input,
} from 'reactstrap';
import { v4 as uuid } from 'uuid';

const memberMenu = (onClick, availableMembers) => {
  const dropdownId = uuid();
  const inputId = uuid();
  return (
    <DropdownMenu id={dropdownId}>
      <div style={{ padding: 10 }}>
        <Input
          id={inputId}
          placeholder='Buscar'
          onKeyUp={() => filterFunction(inputId, dropdownId)}
        />
      </div>
      {availableMembers.length ? (
        availableMembers
          .filter((m) => m.isVisible)
          .map((m) => (
          <DropdownItem key={m.name} onClick={() => onClick(m)}>
            {m.title}
          </DropdownItem>
        ))
      ) : (
        <DropdownItem disabled>Não disponível</DropdownItem>
      )}
    </DropdownMenu>
  );
};

const filterFunction = (inputId, dropdownId) => {
  console.log(inputId);
  const input = document.getElementById(inputId);
  const filter = input.value.toUpperCase();
  const dropdown = document.getElementById(dropdownId);
  const links = Array.from(dropdown.getElementsByTagName('button'));
  links.map((link) => {
    const txtValue = link.textContent || link.innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      link.style.display = '';
    } else {
      link.style.display = 'none';
    }
    return txtValue;
  });
};

const MemberDropdown = ({
  onClick,
  availableMembers,
  ...dropdownToggleProps
}) => {
  return (
    <UncontrolledDropdown direction='right'>
      <DropdownToggle {...dropdownToggleProps}></DropdownToggle>
      {memberMenu(onClick, availableMembers)}
    </UncontrolledDropdown>
  );
};

MemberDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  availableMembers: PropTypes.array.isRequired,
};
export default MemberDropdown;

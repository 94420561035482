import React from 'react';
import Accordion from '../components/helpSection/Accordion';
import intelibot from '../images/intelibot.svg'

const HelpPage = () => {
  return (
    <div className='help-section'>
      <img src={intelibot} alt="intelibot" className="intelibot-image center-elem-margin"/>
      <h1>Olá, como podemos lhe ajudar?</h1>
      <Accordion />
      <div className="help-footer" />
    </div>
  );
}

export default HelpPage;

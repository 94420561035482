import React from "react";
import Honeybadger from "@honeybadger-io/js";
import { Button } from 'antd';


Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
  breadcrumbsEnabled: false,
})

class ChartError extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Honeybadger.notify(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="center-elem-margin">
          <h1>Não foi possível renderizar os gráficos com os dados selecionados</h1>
          <Button
            type="primary"
            onClick={() => this.setState({ hasError: false })}
            block
          >
            Tentar novamente
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ChartError;

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { content } from './helpSection/content';


const welcomingMessage = content[0].content;

function ModalWelcomeVideo() {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    localStorage.setItem("firstAccess", "false")
    setModal(!modal)
  };

  return (
    <div>
      <Modal isOpen={!localStorage.getItem("firstAccess")} toggle={toggle} size='lg'>
        <ModalHeader toggle={toggle} className="welcome-modal-header">
          Que bom que você chegou às dashboards! Estávamos te esperando!
        </ModalHeader>
        <ModalBody>
          { welcomingMessage }
          <p className="justify-text">Se preferir, você pode ir direto para a tour virtual, clicando no botão abaixo.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Ir para tour
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalWelcomeVideo;

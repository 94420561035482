export const IS_DATE = (arg) => {
  return (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}/gm).test(arg)
};
export const fullHDscreenWidth = 1920;
export const LINK_ZENLOBBY = 'https://app.inteligov.com.br/painel';
export const MAX_BARS_NUMBER = 30;
export const MAX_PIE_SLICES = 10;
export const MAX_TABLE_ENTRY_SIZE = 50;
export const MAX_TABLE_PIVOT_SIZE = 500;
export const MAX_BARCHART_HEIGHT = 1000;
export const MIN_BARCHART_HEIGHT = 500;

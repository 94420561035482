import IntroVideo from './IntroVideo';

export const content = [
  {
    // This should be kept at position 0, as it is re-used on the welcome modal
    title: "Primeiros passos",
    content: (
      <>
        <p className="justify-text">
          O Inteligov Dashboards é o produto desenvolvido para te oferecer análises mais autônomas com gráficos, tabelas e relatórios personalizados!
          Obtenha insights valiosos para a tomada de decisão e mais valor agregado ao seu trabalho.
        </p>
        <p className="justify-text">
          Assista ao vídeo e saiba como explorar todas as possibilidades!
        </p>
        <IntroVideo/>
      </>)
  },
];
import axios from 'axios';

const backendURI = process.env.REACT_APP_LOGIN_URI;
const jwtToken = window.localStorage.getItem("jwt");
const headers = {
  Authorization: jwtToken,
};

export async function getDashboards() {
  const dashboards = await axios.get(
    `${backendURI}/dashboards/v1/dashboards/get`,
    { headers })
    .then((response) => response.data.data);
  return dashboards;
};

export async function getSingleDashboard(id) {
  const dashboard = await axios.get(
    `${backendURI}/dashboards/v1/dashboards/get/${id}`,
    { headers })
    .then((response) => response.data);
  return dashboard;
};

export async function saveDashboard(name, vizState) {
  const body = {
    dashboardConfig: {
      name,
      vizState,
    }
  };

  const dashboard = await axios.post(
    `${backendURI}/dashboards/v1/dashboards/post`,
    body,
    { headers })
    .then((response) => response.data);
  return dashboard;
};

export async function updateDashboard(id, name, vizState) {
  const body = {
    dashboardConfig: {
      name,
      vizState,
    }
  };

  const dashboard = await axios.patch(
    `${backendURI}/dashboards/v1/dashboards/edit/${id}`,
    body,
    { headers })
    .then((response) => response.data);
  return dashboard;
};

export async function updateLayout(id, layout) {
  const body = {
    layout,
  };

  const newLayout = await axios.patch(
    `${backendURI}/dashboards/v1/dashboards/edit/${id}/update_layout`,
    body,
    { headers })
  .then((response) => response.data)

  return newLayout;
}

export async function deleteDashboards(idsArray) {
  const config = {
    headers,
    data: {
      dashboardIds: idsArray,
    }
  };

  const ids = await axios.delete(
    `${backendURI}/dashboards/v1/dashboards/deleteSeveral`,
    config)
    .then((response) => response.data);
  return ids;
};

import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5exporting from '@amcharts/amcharts5/plugins/exporting';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { v4 as uuid } from 'uuid';

function AreaChartAM({ resultSet, chartSize }) {
  const id = uuid();

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new(id);


    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX:true,
        layout: root.verticalLayout
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    let xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      oversizedBehavior: "truncate"
    });

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "x",
      startLocation: 0.5,
      endLocation: 0.5,
      renderer: xRenderer,
      // tooltip: am5.Tooltip.new(root, {})
    }));

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    // Set data
    const data = resultSet.chartPivot();
    data.map((value) => delete value.xValues)
    xAxis.data.setAll(data);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    function createSeries(name, field) {
      let series = chart.series.push(am5xy.LineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        stacked:true,
        valueYField: field,
        categoryXField: "x",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
        })
      }));

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
      });

      series.data.setAll(data);
      series.appear(1000);
    }

    // Create series, except for the x key. This data object looks like this:
    // {
    //   cmnatal,Bills.count: 1
    //   cmrio,Bills.count: 22
    //   senado,Bills.count: 400
    //   x: "2022-03-01T00:00:00.000"
    // }
    Object.keys(data[0]).forEach((key) => {
      if (key !== 'x') {
        const nameValue = key.split(',')[0]
        createSeries(nameValue, key)
      }
    })

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal"
    }));

    // Adds option to export data as an image or data
    // https://www.amcharts.com/docs/v5/concepts/exporting/
    am5exporting.Exporting.new(root, {
      menu: am5exporting.ExportingMenu.new(root, {
        align: "left",
      }),
      dataSource: resultSet.chartPivot(),
      numericFields: resultSet.seriesNames(),
      XLSXOptions: {
        disabled: false,
      },
    });

    // Add legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50)
    }));
    legend.labels.template.setAll({
      maxWidth: 150,
      oversizedBehavior: "truncate"
    })
    legend.data.setAll(chart.series.values);
    
    // Auto-adjusting chart height
    root.events.once("frameended", () => {
      const chartHeight = 40 * chartSize
      chart.root.dom.style.height = chartHeight + "px";
    });

    return () => {
      root.dispose();
    };

  }, [resultSet, chartSize, id]);
  return (
    <div id={id} style={{ width: "100%" }}></div>
  );
}

export default AreaChartAM;

import React from 'react';
import { Collapse } from 'antd';
import { content } from './content';

const { Panel } = Collapse;

const Accordion = () => {
  return (
    <Collapse>
      {content.map((entry, index) => (
        <Panel header={entry.title} key={index} className="help-section-title">
          {entry.content}
        </Panel>
      ))}
    </Collapse>
  )
}

export default Accordion;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import { Statistic } from 'antd';
import { Row, Col, Spinner, Table } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { useDeepCompareMemo } from 'use-deep-compare';
// import { isCompositeType } from 'graphql';
import { BarChartAM, AreaChartAM, LineChartAM, PieChartAM, SizeSlider } from './amcharts5';
import { ChartError, CubeError } from '../errorHandlers';
import { MAX_TABLE_ENTRY_SIZE } from '../constants';
import { chartNeedsResize, displayDate, isDate, shouldLimitData, truncateTableData } from '../helpers'
import { dataSizeLimitMessages } from '../messages/dataSizeLimitMessages';

const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value === undefined) {
      return value;
    }

    if (type === 'boolean') {
      if (typeof value === 'boolean') {
        return value.toString();
      } else if (typeof value === 'number') {
        return Boolean(value).toString();
      }

      return value;
    }

    if (type === 'number' && format === 'percent') {
      return [parseFloat(value).toFixed(2), '%'].join('');
    }

    return value?.toString();
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }

  return data.map(format);
};

const TableRenderer = ({ resultSet, pivotConfig }) => {
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    const columns = resultSet.tableColumns(pivotConfig);
    return [
      columns,
      formatTableData(columns, resultSet.tablePivot(pivotConfig)),
    ];
  }, [resultSet, pivotConfig]);
  const { granularity } = resultSet.query().timeDimensions[0] || '';

  return (
    <Table bordered striped hover responsive>
      <thead>
        <tr>
          {tableColumns.map((column, index) => {
            return <th key={index}>{column.title}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {dataSource.map((row) => {
          return (
            <tr key={uuid()}>
              {Object.entries(row).map((cell) => {
                const dataToDisplay = truncateTableData(cell[1], MAX_TABLE_ENTRY_SIZE);
                return (
                  <td key={uuid()} title={dataToDisplay.length < MAX_TABLE_ENTRY_SIZE ? null : cell[1]}>
                    {isDate(dataToDisplay) ? displayDate(dataToDisplay, granularity) : dataToDisplay}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const bar = ({ resultSet, chartSize }) => {
  return <BarChartAM resultSet={resultSet} chartSize={chartSize}/>
};

const area = ({ resultSet, chartSize }) => {
  return <AreaChartAM resultSet={resultSet} chartSize={chartSize}/>
}

const line = ({ resultSet, chartSize }) => {
  return <LineChartAM resultSet={resultSet} chartSize={chartSize}/>
}

const pie = ({ resultSet, chartSize }) => {
    return <PieChartAM resultSet={resultSet} chartSize={chartSize}/>
}

const TypeToChartComponent = {
  line,
  bar,
  area,
  pie,
  number: ({ resultSet }) => {
    return (
      <Row
        type='flex'
        justify='center'
        align='middle'
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            <Statistic value={resultSet.totalRow()[s.key]} />
          ))}
        </Col>
      </Row>
    );
  },
  table: ({ resultSet, pivotConfig }) => {
    return <TableRenderer resultSet={resultSet} pivotConfig={pivotConfig} />;
  },
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = (Component) => {
  return ({ resultSet, error, pivotConfig, chartSize, setDataSize }) => {
    return (
      (resultSet && (
        <Component resultSet={resultSet} pivotConfig={pivotConfig} chartSize={chartSize} setDataSize={setDataSize}/>
      )) ||
      (error && <CubeError error={error}/>) || 
      (<Spinner color='primary' className='center-elem-margin'>Carregando...</Spinner>)
    );
  };
};

const DataSizeMessage = ({ chartType }) => {

  return (
    <span style={{'paddingLeft': '25px'}}>
      {dataSizeLimitMessages[chartType]}
    </span>
  );
}

const ChartRenderer = ({ vizState, dataSizeState }) => {

  const { query, chartType, pivotConfig } = vizState;
  const {setDataSize, dataSize} = dataSizeState || {};
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  const isExplorePage = renderProps.resultSet && typeof dataSizeState !== 'undefined';

  const sizeInfo = {
    defaultChartSize: 20,
    minChartSize: 10,
    maxChartSize: 40,
  };
  
  const [chartSize, setChartSize] = useState(sizeInfo.defaultChartSize);

  useEffect(() => {
    if (isExplorePage) {
      const currentDataSize = renderProps.resultSet.chartPivot().length;
      setDataSize(currentDataSize);
    }
  }, [isExplorePage, renderProps.resultSet, setDataSize])

  return (
    <ChartError>
      <div style={{display: 'flex', flexDirection: 'column', width: '100%' }}>
        {shouldLimitData(dataSize, chartType) && <DataSizeMessage chartType={chartType} />}
        {component && chartNeedsResize(chartType) && <SizeSlider setChartSize={setChartSize} sizeInfo={sizeInfo}/>}
        {component && renderChart(component)({ ...renderProps, pivotConfig, chartSize, setDataSize })}
      </div>
    </ChartError>
  );
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null,
};
export default ChartRenderer;

import { MAX_BARS_NUMBER, MAX_PIE_SLICES } from "../constants"

const shouldLimitData = (dataSize, chartType) => {

  const typeSizeCheck = {
    bar: dataSize > MAX_BARS_NUMBER,
    number: false,
    pie: dataSize > MAX_PIE_SLICES,
    table: false,
  }

  return typeSizeCheck[chartType];
}

export { shouldLimitData }

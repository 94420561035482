import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5exporting from '@amcharts/amcharts5/plugins/exporting';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { v4 as uuid } from 'uuid';
import {MAX_BARCHART_HEIGHT, MAX_BARS_NUMBER, MAX_PIE_SLICES, MIN_BARCHART_HEIGHT} from '../../constants';
import displayDate from "../../helpers/displayDate";

const dataParserKeyValue = (resultSet, chartType) => {
  const keyValue = resultSet.seriesNames()[0].key;
  const pivot = resultSet.chartPivot({ y: resultSet.pivotQuery().measures });

  const typeSizeCheck = {
    bar: MAX_BARS_NUMBER,
    pie: MAX_PIE_SLICES,
  }

  const data = pivot.slice(0, typeSizeCheck[chartType] || null).map((element) => {
    const keys = element.xValues.map((el) => {
      const isDate = el && (new Date(el) !== "Invalid Date") && !isNaN(new Date(el));

      if (isDate) {
        return displayDate(el);
      } else {
        return el || "Não definido";
      }
    })
    console.log(element)
    return {
      key: keys,
      value: element[resultSet.pivotQuery().measures.filter((x) => x.endsWith('count'))[0]]
    }
  });
  return data;
}

function BarChartAM({ resultSet, chartSize }) {
  const id = uuid();

  useLayoutEffect(() => {
    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout
      })
    );

    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      oversizedBehavior: "truncate",
      maxWidth: 150,
      ellipsis: "..."
    });

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0.3,
      categoryField: "key",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    xAxis.labelsContainer.set("tooltip", am5.Tooltip.new(root, {
      pointerOrientation: "down"
    }));

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));


    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Series 1",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      sequencedInterpolation: true,
      categoryXField: "key",
      maskBullets: false
    }));

    // Styling columns
    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
    series.columns.template.adapters.add("fill", function(fill, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });
    series.columns.template.adapters.add("stroke", function(stroke, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    // Adding bullets on top of bars
    series.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        sprite: am5.Circle.new(root, {
          radius: 15,
          fill: am5.Color.lighten(series.get("fill"), 0.7)
        })
      });
    });
    series.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        sprite: am5.Label.new(root, {
          text: "{valueY}",
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          textAlign: "center",
          populateText: true
        })
      });
    });

    // Set data
    let data = dataParserKeyValue(resultSet, 'bar');
    xAxis.data.setAll(data);
    series.data.setAll(data);

    // Add export to PNG/JPEG/etc module
    am5exporting.Exporting.new(root, {
      menu: am5exporting.ExportingMenu.new(root, {}),
      dataSource: resultSet.chartPivot(),
      numericFields: resultSet.seriesNames(),
      XLSXOptions: {
        disabled: false,
      },
    });

    // Add legend
    let legend = chart.children.push(am5.Legend.new(root, {
      nameField: "categoryX",
      centerX: am5.percent(50),
      x: am5.percent(50),
    }));
    legend.labels.template.setAll({
      maxWidth: 150,
      oversizedBehavior: "truncate"
    })
    legend.data.setAll(series.dataItems);

    // Auto-adjusting chart height
    // https://www.amcharts.com/docs/v5/tutorials/auto-adjusting-chart-height-based-on-a-number-of-data-items/
    series.events.on("datavalidated", function(ev) {
      let seriesSize = ev.target;
      let currentChartSize = seriesSize.chart;
      let xAxis = currentChartSize.xAxes.getIndex(0);

      // Calculate how we need to adjust chart height
      let chartHeight = seriesSize.data.length * (chartSize * 3) + xAxis.height() + currentChartSize.get("paddingTop", 0) + currentChartSize.get("paddingBottom", 0);
      // Set it on chart's container
      if (chartHeight > MAX_BARCHART_HEIGHT) {
        chart.root.dom.style.height = `${MAX_BARCHART_HEIGHT}px`;
      } else if (chartHeight < MIN_BARCHART_HEIGHT) {
        chart.root.dom.style.height = `${MIN_BARCHART_HEIGHT}px`;
      } else {
        chart.root.dom.style.height = `${chartHeight}px`;
      }
    });

    // Create curtain + message to show when wheel is used over chart without CTRL
    let overlay = root.container.children.push(am5.Container.new(root, {
      width: am5.p100,
      height: am5.p100,
      layer: 100,
      visible: false
    }));
                                              
    let curtain = overlay.children.push(am5.Rectangle.new(root, {
      width: am5.p100,
      height: am5.p100,
      fill: am5.color(0x000000),
      fillOpacity: 0.3
    }));

    overlay.children.push(am5.Label.new(root, {
      text: "Use CTRL + Scroll para aproximar/afastar o gráfico",
      fontSize: 30,
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50
    }));

    chart.plotContainer.events.on("wheel", function(ev) {
      // Show overlay when wheel is used over chart
      if (ev.originalEvent.ctrlKey) {
        ev.originalEvent.preventDefault();
        chart.set("wheelX", "panX");
        chart.set("wheelY", "zoomX");
      }
      else {
        chart.set("wheelX", "none");
        chart.set("wheelY", "none");
        overlay.show();
        overlay.setTimeout(function() {
          overlay.hide()
        }, 800);
      }
    });
    
    return () => {
    root.dispose();
    };
  }, [resultSet, chartSize, id]);

  return (
    <div id={id} style={{ width: "100%" }}></div>
  );
}

export default BarChartAM;

import { MAX_BARS_NUMBER, MAX_PIE_SLICES } from "../constants";

const barChartMessage = `Apenas as ${MAX_BARS_NUMBER} primeiras barras serão mostradas. Caso queira mostrar outros dados, refine sua pesquisa.`
const pieChartMessage = `Apenas as ${MAX_PIE_SLICES} maiores fatias serão mostradas. Caso queira mostrar outros dados, refine sua pesquisa.`

const dataSizeLimitMessages = {
  bar: barChartMessage,
  pie: pieChartMessage,
};

export { dataSizeLimitMessages }
const getUserInfoLocalStorage = () => {
  return {
    account_id: localStorage.getItem('account_id') || 'no account_id',
    account_name: localStorage.getItem('account_name') || 'no account_name',
    email: localStorage.getItem('email') || 'no email',
    user_id: localStorage.getItem('user_id') || 'no user_id',
  }
}

export default getUserInfoLocalStorage;

import React from "react";

const LoadingPage = () => {
  return (
    <div className='auth-container'>
    <img
      alt='Logo Inteligov'
      src='https://s3.amazonaws.com/www.zenlobby.com.assets/brands/symbols/inteligov.svg'
      className='auth-logo'
    />
    <h1>Carregando...</h1>
  </div>
  )
}

export default LoadingPage;

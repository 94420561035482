import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Row, Col } from 'reactstrap';
import MemberDropdown from './MemberDropdown';
import filterDimensions from '../../helpers/filterDimensions';
import filterMembersSelected from '../../helpers/filterMembersSelected';

const MemberGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  selectedMeasures,
  updateQuery,
}) => {
  // Removing the members that have already been selected
  let membersToRender = filterMembersSelected(members, availableMembers)
  if (addMemberName === 'Dimensão') {
    // Filtering dimensions according to the measures selected
    membersToRender = filterDimensions(selectedMeasures, membersToRender)
  }

  return (
    <>
      <Row>
        <Col>
          <MemberDropdown
            // Leaving this in case we want to be able to add more than one measure at a time
            // onClick={(m) => updateMethods.add(m)}
            onClick={
              addMemberName === 'Medida' ?
              (m) => updateQuery({ measures: [m.name], timeDimensions: [], dimensions: [], filters: [] }) :
              (m) => updateMethods.add(m)            
            }
            availableMembers={membersToRender}
            color='primary'
            caret
          >
            {addMemberName}
          </MemberDropdown>
        </Col>
      </Row>
      <Row>
        <Col className='mt-2'>
          {members.map((m) => (
            <Row key={m.title}>
              <Col className='mt-1'>
                <Button
                  className='mr-2'
                  color='default'
                  size='sm'
                  style={{
                    fontWeight: 400,
                    fontSize: '.7rem',
                  }}
                  onClick={
                    addMemberName === 'Medida' ?
                    (m) => updateQuery({ measures: [], timeDimensions: [], dimensions: [], filters: [] }) :
                    () => updateMethods.remove(m)
                  }
                >
                  <span>{m.title}</span>
                  <i className='fas fa-times' style={{ fontSize: '.7rem' }}></i>
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
)};

MemberGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired,
};
export default MemberGroup;

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import capitalizeFirstLetter from './capitalizeFirstLetter';

const displayDate = (date, granularity) => {
  const dt = new Date(Date.parse(date));
  const dtDateOnly = dt.getFullYear().toString() + "-" + dt.getMonth() + "-" + dt.getDate()
  const dtFinal = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)

  const formatDate = {
    day: format(dtFinal,"PPP", {locale: ptBR}),
    week: format(dtFinal,"PPP", {locale: ptBR}),
    month: capitalizeFirstLetter(format(dtFinal,"MMMM 'de' yyyy", {locale: ptBR})),
    quarter: capitalizeFirstLetter(format(dtFinal,"MMMM 'de' yyyy", {locale: ptBR})),
    year: format(dtFinal,"yyyy"),
  };

  return formatDate[granularity] || capitalizeFirstLetter(format(dtFinal,"MMMM 'de' yyyy", {locale: ptBR}));
}

export default displayDate;
import React, { useState } from "react";
import { Slider } from 'antd';

function SizeSlider({setChartSize, sizeInfo}) {
  const [timer, setTimer] = useState(null);

  const onChangeSlider = (value) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null)
    }
    setTimer(
      setTimeout(() => {
        setChartSize(value)
      }, 1000)
    );
  }

  const { defaultChartSize, minChartSize, maxChartSize } = sizeInfo;

  return (
    <div style={{ padding: '0 25px' }}>
      <h2>Altura do gráfico</h2>
      <Slider
        defaultValue={defaultChartSize}
        min={minChartSize}
        max={maxChartSize}
        onChange={onChangeSlider}
        style={{width: '100%'}}
      />
    </div>
  )
}

export default SizeSlider;

import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button, Col, Row } from 'reactstrap';

const orderOptions = ['asc', 'desc', 'none'];

const getNextOrder = (order) => {
  const index = orderOptions.indexOf(order) + 1;
  console.log(index);
  return orderOptions[index > 2 ? 0 : index];
};

export default function DraggableItem({
  id,
  index,
  order = 'none',
  children,
  onOrderChange,
  title,
}) {
  return (
    <Draggable draggableId={id} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
          <Row
            className='my-2'
            style={{
              width: 380,
              padding: '6px 0 3px 0',
              marginLeft: 5,
            }}
          >
            <Col sm='1'>
              <i className='fas fa-grip-vertical mr-2 text-muted'></i>
            </Col>
            <Col sm='8' className='text-truncate'>
              {title}
            </Col>
            <Col
              sm='3'
              className='d-flex'
              style={{ justifyContent: 'right !important' }}
            >
              <Button
                color={order !== 'none' ? 'primary' : 'secondary'}
                size='sm'
                onClick={() => onOrderChange(id, getNextOrder(order))}
              >
                {order.toUpperCase()}
              </Button>
            </Col>
          </Row>
          {children}
        </div>
      )}
    </Draggable>
  );
}

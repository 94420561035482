import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Row } from 'reactstrap';
import DraggableItem from './DraggableItem';

function OrderGroup({ orderMembers, onOrderChange, onReorder }) {
  return (
    <DragDropContext
      onDragEnd={({ source, destination }) => {
        onReorder(source && source.index, destination && destination.index);
      }}
    >
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {orderMembers.map(({ id, title, order }, index) => (
              <Row>
                <DraggableItem
                  key={id}
                  id={id}
                  index={index}
                  order={order}
                  onOrderChange={onOrderChange}
                  toggle={false}
                  title={title}
                ></DraggableItem>
              </Row>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default OrderGroup;

import React from "react";

function CubeError({ error }) {
  let messageToDisplay;
  if (error.message.indexOf("filter") !== -1) {
    messageToDisplay = "Aguardando o preenchimento do filtro"
  } else if (error.message.indexOf("path to join") !== -1) {
    messageToDisplay = "Não é possível juntar as medidas selecionadas"
  } else {
    messageToDisplay = "Houve um erro ao montar o gráfico. Por favor entre em contato conosco"
  }
  return (
    <p>{messageToDisplay}</p>
  );
}

export default CubeError;

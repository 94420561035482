import React, { useEffect, useState } from 'react';
import { Alert, Button, Spinner } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ExploreQueryBuilder from '../components/QueryBuilder/ExploreQueryBuilder';
import TitleModal from '../components/TitleModal.js';
import { MixPanel } from '../helpers/MixPanel';
import { getSingleDashboard } from '../helpers/api';
import LoadingPage from '../components/LoadingPage';

const ExplorePage = withRouter(({ history, location }) => {
  const [addingToDashboard, setAddingToDashboard] = useState(false);
  const params = new URLSearchParams(location.search);
  const itemId = params.get('itemId');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [vizState, setVizState] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getSingleDashboard(itemId);
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    MixPanel.explorePageHit();
  }, [itemId]);

  const finalVizState =
    vizState ||
    (itemId && !loading && data && data.dashboard_config.vizState) ||
    {};
  const [titleModalVisible, setTitleModalVisible] = useState(false);
  const [title, setTitle] = useState(null);
  const finalTitle =
    title != null
      ? title
      : (itemId && !loading && data && data.dashboard_config.name) || '';

  if (error) {
    return <Alert type='error' message={error.toString()} />;
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <div>
      <TitleModal
        history={history}
        itemId={itemId}
        titleModalVisible={titleModalVisible}
        setTitleModalVisible={setTitleModalVisible}
        setAddingToDashboard={setAddingToDashboard}
        finalVizState={finalVizState}
        setTitle={setTitle}
        finalTitle={finalTitle}
      />
      <ExploreQueryBuilder
        vizState={finalVizState}
        chartExtra={[
          <Button
            key='button'
            color='primary'
            onClick={() => {
              setTitleModalVisible(true);
            }}
            className='float-right'
          >
            {itemId ? 'Atualizar' : 'Adicionar ao seu Dashboard'}
          </Button>,
        ]}
        onVizStateChanged={setVizState}
      />
    </div>
  );
});
export default ExplorePage;
